import React from 'react'

const Textarea = ({ field: { type, name, placeholder, label, id }, handleChange }) => {
    return (
        <div>
            <label className="form__label" htmlFor={id}>{label}</label>
            <textarea className="form__textarea" id={id} name={name} onChange={handleChange}></textarea>
        </div>
    )
}

export default Textarea;
