import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './BottomNav.css';

const BottomNav = () => {
    const appSelector = useSelector((state) => state.app);
    const { navigation } = appSelector;

    return (
        <div className="bottomNav">
            {navigation && navigation.map((item) => {
                return <NavLink className="bottomNav__link" activeClassName="active" to={item.path} exact>{item.icon && (<div dangerouslySetInnerHTML={{__html: item.icon}} />)}<div>{item.label}</div></NavLink>
            })}
        </div>
    )
}

export default BottomNav;
