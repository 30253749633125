import React from 'react'

const Checkbox = ({ field: { name, placeholder, label, id, defaultValue }, handleChange }) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
            <label className="form__label" style={{ paddingRight: '0.5rem' }} htmlFor={id}>{label}</label>
            <div>
                <input id={id} type="checkbox" name={name} placeholder={placeholder} value={defaultValue} onChange={handleChange} />
            </div>
        </div>
    )
}

export default Checkbox;
