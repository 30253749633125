import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Subpage from './pages/Subpage';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Card from './pages/Card/Card';
import ProtectedRoute from './components/routes/ProtectedRoute';

import { getMenu, getNavigation } from './actions/appActions'; 

import './App.css';

const App = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const appSelector = useSelector((state) => state.app);
  const { isAuthenticated } = authSelector;
  const { navigation } = appSelector;

  // Temp solution until server can be accessed through localhost
  const TestRoute = process.env.NODE_ENV === 'development' ? Route : ProtectedRoute;

  useEffect(() => {
    dispatch(getMenu());
    dispatch(getNavigation());
  }, [dispatch])

  return (
    <Router>
      <div className={`app ${window.location.pathname === '/login' ? 'no-transition' : ''}`}>
        <Switch>
          {navigation && navigation.map((item) => {
            return (
              <TestRoute key={item.id} path={item.path} exact component={Subpage} isAuthenticated={isAuthenticated} />
            );
          })}
          <Route path="/login" component={Login} />
          <TestRoute path="/logout" component={Logout} isAuthenticated={isAuthenticated} />
          <TestRoute path="/card" component={Card} isAuthenticated={isAuthenticated} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
