import React from 'react';

import Photograph from '../../images/photograph.svg';

const File = ({ field: { name, label, id, show_image, multiple }, fileSelectedHandler }) => {
    return (
        <div>
            <label className="form__label" style={{ marginTop: '3.5rem' }} htmlFor={id}>{label}</label>
            <div>
                <div>{show_image && (<img src={Photograph} alt="Uploaded file" />)}</div>
                <input id={id}  type="file" name={name} onChange={fileSelectedHandler} multiple={multiple}/>
            </div>
        </div>
    )
}

export default File;
