import React, { useEffect, useState } from 'react';

const options = [
    {
        field_option_id: 1,
        option_name: "Yes"
    },
    {
        field_option_id: 2,
        option_name: "No"
    }
];

const Select = ({ field: { type, name, label, id }, handleChange, handleDefaultCosts, handleScore, defaultValue, errorClass }) => {
    const handleLocalChange = event => {
        handleChange(event);
    }

    useEffect(() => {
        const event = { target: { name, value: defaultValue }};

        handleLocalChange(event)
    }, [defaultValue]);

    return (
        <div className={errorClass}>
            <label className="form__label" htmlFor={id}>{label}</label>
            <div className="selectContainer">
                <select className="form__select" id={id} type={type} name={name} onChange={handleLocalChange} defaultValue={defaultValue}>
                    <option value="">-- Please select an option --</option>
                    {options && options.map((option) => (
                        <option value={option?.field_option_id}>{option?.option_name}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default Select;
