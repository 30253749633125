import axios from 'axios';
import Cookie from 'js-cookie';

export const handleForm = async (url, formId, formData, type) => {
    const fd = new FormData();
    let submitType;

    if (type === 'FORMS') {
        submitType = 'FORM_ENTRIES';
    } else if (type === 'MISC') {
        submitType = 'MISC_ENTRIES';
    }

    for (const [key, value] of Object.entries(formData)) {
        if (value.type === 'file') {
            if (typeof value.value === 'object') {
                for (const file in value.value) {
                    if (typeof value.value[file] === 'object') {
                        fd.append([key + '[]'], value.value[file], value.value[file].name);
                    }
                }
            } else {
                fd.append(key, value.value);
            }
        } else {
            fd.append(key, value.value);
        }
    }

    const { data } = await axios.post(url, fd, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Form-Id': formId,
            'Wg-Method': submitType,
            'Wg-Key': Cookie.get('accessToken')
        }
    });

    return data;
}