import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Logo from '../../assets/eha-app-logo.png';
import LogoShrink from '../../assets/eha-app-logo.png';
import Menu from '../../images/menu.svg';

import { toggleMenu } from '../../actions/appActions';

import './Header.css';

const Header = (props) => {
    const dispatch = useDispatch();
    const [shrink, setShrink] = useState(false);
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;
    const { isLogin } = props;

    useEffect(() => {
            window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 108) {
            setShrink(true);
        } else {
            setShrink(false);
        }
    };

    return (
        <div className={`header ${shrink ? 'header--shrink' : ''}`}>
            <div className="header__top">
                <img className="header__logo" src={shrink ? LogoShrink : Logo} alt="EHA" />
                <div className="header__topMessage">{window.location.pathname === '/' ? `Welcome ${user?.displayName}` : props.children}</div>
                {!isLogin && (
                    <button aria-label="Menu" onClick={() => dispatch(toggleMenu())} className="header__menuIcon"><img src={Menu} alt="Menu" /></button>
                )}
            </div>
        </div>
    )
}

export default Header;
