import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/layout/Header';
import Menu from '../components/layout/Menu';
import BottomNav from '../components/layout/BottomNav';
import Form from '../components/forms/Form';

import { closeMenu } from '../actions/appActions';

import './Subpage.css';

const Subpage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;
    const [page, setPage] = useState(null);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const { data } = await axios.get('app_handler.php', { headers: { 'Wg-Method': 'PAGES', 'Page-Path': location.pathname }});

                if (data) {
                    setPage(data);
                }
            } catch (error) {
                console.error(error);
                // const { pages } = require('../data.json');
                // const tempPage = pages.filter((p) => p.path === location.pathname)[0];
                
                // setPage(tempPage);
            }
        };

        fetchPage();
        dispatch(closeMenu());

    }, [location.pathname, dispatch]);

    return (
        <Fragment>
            <Header>{page?.title}</Header>
            <Menu />
            {page?.show_membership && user?.expiry_date && (
                <div className="header__membership">Your membership is valid until {user?.expiry_date}</div>
            )}
            <div className="page" style={{ backgroundColor: page?.background_color, marginTop: page?.show_membership && user?.expiry_date && '0' }}>
                {page?.form_id && (<Form id={page?.form_id || page?.misc_id} type={page?.form_type} />)}
                <div dangerouslySetInnerHTML={{__html: page?.content}} />
            </div>
          <BottomNav />
        </Fragment>
    );
};

export default Subpage;