import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import QRCode from 'react-qr-code';
import Axios from 'axios';
import Cookie from 'js-cookie';
import ClipLoader from 'react-spinners/ClipLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import ProfilePlaceholder from '../../assets/profile-placeholder.jpg';

import { closeMenu } from '../../actions/appActions';

import './Card.css';

const Card = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState(null);

    useEffect(() => {
        const fetchCard = async () => {
            setLoading(true);
            try {
                const { data } = await Axios.get('/app_handler.php', { headers: { 'Wg-Method': 'MEMBERSHIP_CARD', 'Wg-Key': Cookie.get('accessToken') }});

                if (data) {
                    setLoading(false);
                    setCard(data);
                }
            } catch (error) {
                setLoading(false);
                // setCard({
                //     qrcode_value: '1234',
                //     firstname: 'Adam',
                //     lastName: 'Gray',
                //     company: 'Webglu Limited test 2 test',
                //     telephone: '01934 813487',
                //     mobile_number: '07525359332',
                //     email: 'adam@webglu.co.uk',
                //     profile_image: 'http://app.napfis.co.uk/images/profile-pic-placeholder.png',
                //     membership_number: '1',
                //     expiry_date: '12/02/1233'
                // });
            }
        };

        dispatch(closeMenu());
        fetchCard();
    }, [dispatch]);

    return (
        <Fragment>
            <Header>Membership Card</Header>
            <Menu />
            <div className="page" style={{ backgroundColor: '#00491E' }}>
            {loading ? (
                <div>
                    <ClipLoader color={'#FFF'} />
                </div>
            ) : (
                <Fragment>
                    {card ? (
                        <div className="card card--bgGrey">
                            <div className="card__row">
                                <div className="card__membershipDetails">
                                    <div className="text--md text--uppercase leading-none font-medium">{card?.lastname}</div>
                                    <div className="text--thin text--md font-normal">{card?.firstname}</div>
                                    <div className="text--md mt-4 font-medium">{card?.company}</div>
                                    <div className="text--md mt-1 font-medium">{card?.telephone}</div>
                                    <div className="text--md mt-1 font-medium">{card?.mobile_number}</div>
                                </div>
                                <div className="card__profile">
                                    <img className="card__profilePicture" src={card?.profile_image ? card.profile_image : ProfilePlaceholder } alt={`${card?.firstname}'s Profile`} />
                                </div>
                            </div>
                            <div className="card__row mt-4">
                                <div className="card__membershipDetails" style={{ justifyContent: 'space-between' }}>
                                    <div>
                                        <div className="text--md">Membership No:</div>
                                        <div className="text--md text--thin">{card?.membership_number}</div>
                                    </div>
                                    <img className="card__membershipImage" src="https://www.webglu17.co.uk/images/iso-logo.png" alt="ISO" />
                                </div>
                                <div className="card__profile">
                                    <div className="text--md" style={{ textAlign: 'left' }}>Expiry:</div> 
                                    <div className="text--md text--thin" style={{ textAlign: 'left' }}>{card?.expiry_date}</div>
                                    {card?.qrcode_value && (
                                        <div className="card__qrCode">
                                            <QRCode value={card?.qrcode_value} size={120} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>Could not load membership card.</div>
                    )}
                </Fragment>
            )}
            </div>
            <BottomNav />
        </Fragment>
    )
}

export default Card;
