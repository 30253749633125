import React from 'react'

const Input = ({ field: { type, name, placeholder, label, id, defaultValue }, handleChange }) => {

    return (
        <div>
            {type !== 'hidden' && (<label className="form__label" htmlFor={id}>{label}</label>)}
            <input className="form__input" id={id} type={type} name={name} placeholder={placeholder} value={defaultValue} onChange={handleChange} />
        </div>
    )
}

export default Input;
